/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Title from "./title"
import "./layout.css"
import Contact from "./FormComponent/contact"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title}/>
      <main>{children}</main>
      <footer>
        <div className="bg-gray-100 py-10">
          <div className="container mx-auto">
            <Title align="text-center" title="Getting in touch"/>
            <div className="flex flex-col">
              <div className="flex-1 max-w-xl mt-8 text-center mx-auto">
                <p className="font-body text-md mb-10 font-hairline">
                  For more information or for our wholesale daily offers, please get in touch – we'd be more than happy
                  to help.
                </p>
                <Contact showTitle={false}/>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white py-3">
          <div className="container mx-auto text-center">
            <div className="flex flex-col items-center max-w-sm mx-auto">
              <div className="flex-auto">
                Call: <a className="font-bold" href="tel:447415406590">(+44) 741 5406 590</a>
              </div>
              <div className="flex-auto">
                Email: <a className="font-bold" href="mailto:info@monaghanmedialtd.co.uk">info@monaghanmedialtd.co.uk</a>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-xs">
                Monaghan Media LTD - Registered company in England and Wales - registration number: GB10574046 - VAT
                number - 203958016
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
