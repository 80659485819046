import React, { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"

const Contact = ({ showTitle = true }) => {
  const { register, errors, handleSubmit } = useForm()
  const [success, setSuccess] = useState(false)

  const onSubmit = async (data, e) => {
    e.preventDefault()
    if (data.catch_honeypot !== "") return
    try {
      const response = await axios.post("https://formspree.io/mdogwdne", data)

      console.log(response.data)
      setSuccess(true)
      e.target.reset()
    } catch (err) {
      setSuccess(false)
      console.log(err.errors.data)
    }
  }

  return (
    <form className="bg-white shadow-xl rounded px-8 pt-6 pb-8 mb-4 text-left" onSubmit={handleSubmit(onSubmit)}
          noValidate>
      {
        showTitle ?
          <h1 className="text-4xl text-center font-black tracking-wide text-blue-500 uppercase antialiased mb-6">Find
            out more
            today!</h1> : null
      }
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-first-name">
            First Name
          </label>
          <input
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${errors.firstName ? "border-red-500" : "border-gray-200"} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
            id="grid-first-name"
            type="text"
            name="firstName"
            ref={register({ required: true, pattern: /^[a-zA-Z]+$/i })}
            placeholder="Jane"/>
          {errors.firstName && errors.firstName.type === "required" &&
          <p className="text-red-500 text-xs italic">First name is required.</p>}
          {errors.firstName && errors.firstName.type === "pattern" &&
          <p className="text-red-500 text-xs italic">Last name is not valid.</p>}
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                 htmlFor="grid-last-name">
            Last Name
          </label>
          <input
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${errors.lastName ? "border-red-500" : "border-gray-200"} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
            id="grid-last-name"
            type="text"
            name="lastName"
            ref={register({ required: true, pattern: /^[a-zA-Z]+$/i })}
            placeholder="Doe"/>
          {errors.lastName && errors.lastName.type === "required" &&
          <p className="text-red-500 text-xs italic">Last name is required.</p>}
          {errors.lastName && errors.lastName.type === "pattern" &&
          <p className="text-red-500 text-xs italic">Last name is not valid.</p>}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
            Email Address
          </label>
          <input
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${errors.email ? "border-red-500" : "border-gray-200"} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
            id="grid-email"
            type="email"
            name="email"
            ref={register({
              required: true,
              pattern: /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            })}
            placeholder="janedoe@example.com"/>
          {
            errors.email &&
            errors.email.type === "required" &&
            <p className="text-red-500 text-xs italic">Email address is required.</p>
          }
          {
            errors.email &&
            errors.email.type === "pattern" &&
            <p className="text-red-500 text-xs italic">Email address is not valid!</p>
          }
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
            Message
          </label>
          <input style={{ display: "none" }} type="text" name="catch_honeypot" ref={register}/>
          <textarea
            className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${errors.message ? "border-red-500" : "border-gray-200"} rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
            id="grid-message"
            name="message"
            ref={register({ required: true, minLength: 20, maxLength: 400 })}
            rows={4}></textarea>
          {errors.message && errors.message.type === "required" &&
          <p className="text-red-500 text-xs italic">Message is required.</p>}
          {errors.message && errors.message.type === "maxLength" &&
          <p className="text-red-500 text-xs italic">Message must be less than 400</p>}
          {errors.message && errors.message.type === "minLength" &&
          <p className="text-red-500 text-xs italic">Message must be greater than 20</p>}
        </div>
      </div>
      {
        !success &&
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <button
              className="bg-blue-500 w-full hover:bg-blue-700 text-white font-light py-2 px-4 rounded focus:outline-none focus:shadow-outline uppercase"
              type="submit">
              Submit your message
            </button>
          </div>
        </div>
      }
      {
        success &&
        <div className="flex flex-wrap -mx-3 bg-green-200">
          <div className="w-full p-3 text-center text-teal-700">
            <h3 className="font-bold capitalize leading-4">Thanks for getting in touch</h3>
            <p className="font-light mt-4 antialiased">We will get back to you as soon as possible.</p>
          </div>
        </div>
      }
    </form>
  )
}

export default Contact